
import { defineComponent } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";

export default defineComponent({
  name: "kt-explore",
  setup() {
    const is_admin = null;
    const is_client = null;

    return {
      is_admin,
      is_client
    }
  },

});
