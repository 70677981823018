import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y my-5 my-lg-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_4 = { class: "menu-content pt-8 pb-2" }
const _hoisted_5 = { class: "menu-section text-muted text-uppercase fs-8 ls-1" }
const _hoisted_6 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_7 = ["href", "onClick"]
const _hoisted_8 = {
  key: 0,
  class: "menu-icon"
}
const _hoisted_9 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_10 = { class: "menu-title" }
const _hoisted_11 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_12 = ["href", "onClick"]
const _hoisted_13 = { class: "menu-icon" }
const _hoisted_14 = { class: "svg-icon svg-icon-2" }
const _hoisted_15 = { class: "menu-title" }
const _hoisted_16 = { class: "menu-item" }
const _hoisted_17 = { class: "menu-content pt-8 pb-2" }
const _hoisted_18 = { class: "menu-section text-muted text-uppercase fs-8 ls-1" }
const _hoisted_19 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_20 = ["href", "onClick"]
const _hoisted_21 = { class: "menu-icon" }
const _hoisted_22 = { class: "svg-icon svg-icon-2" }
const _hoisted_23 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, i) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
          (item.heading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate(item.heading)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.pages, (menuItem, j) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
              (menuItem.heading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: menuItem.route
                    }, {
                      default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                        _createElementVNode("a", {
                          class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                          href: href,
                          onClick: navigate
                        }, [
                          (menuItem.svgIcon || menuItem.fontIcon)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                (_ctx.asideMenuIcons === 'font')
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 0,
                                      class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                    }, null, 2))
                                  : (_ctx.asideMenuIcons === 'svg')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                        _createVNode(_component_inline_svg, {
                                          src: '/' + menuItem.svgIcon
                                        }, null, 8, ["src"])
                                      ]))
                                    : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.translate(menuItem.heading)), 1)
                        ], 10, _hoisted_7)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ], 64))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (menuItem, j) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
          (menuItem.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_router_link, {
                  to: `/page/${menuItem.slug}`
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("a", {
                      class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                      href: href,
                      onClick: navigate
                    }, [
                      _createElementVNode("span", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, [
                          (!menuItem.favicon)
                            ? (_openBlock(), _createBlock(_component_inline_svg, {
                                key: 0,
                                src: `/media/icons/duotune/general/gen01${j}.svg`
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.translate(menuItem.title)), 1)
                    ], 10, _hoisted_12)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128)),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('categories')), 1)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category, j) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
          (category.name)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_router_link, {
                  to: `/category/${category.id}`
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("a", {
                      class: _normalizeClass([[isActive && 'active', isExactActive && 'active'], "menu-link"]),
                      href: href,
                      onClick: navigate
                    }, [
                      _createElementVNode("span", _hoisted_21, [
                        _createElementVNode("span", _hoisted_22, [
                          (!category.favicon)
                            ? (_openBlock(), _createBlock(_component_inline_svg, {
                                key: 0,
                                src: `/media/icons/duotune/general/gen03${j}.svg`
                              }, null, 8, ["src"]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.translate(category.name)), 1)
                    ], 10, _hoisted_20)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ], 512))
}