
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  components: {},

  props:{
    user:Object,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const site_url = process.env.VUE_APP_BACKEND_URL
    const signOut = () => {
      store
        .dispatch(Actions.SIGNOUT)
        .then(() => router.push({ name: "dashboard" }));
    };



    return {
      signOut,
      site_url
    };
  },
});
