
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    data: Object,
  },
  data() {
    return {
      site_url: process.env.VUE_APP_BACKEND_URL
    }
  },
  name: "kt-results",
  components: {},
});
