import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "kt_explore_toggle",
  class: "btn btn-sm btn-white btn-active-primary shadow-sm position-fixed px-5 fw-bolder zindex-2 top-50 mt-10 end-0 transform-90 fs-6",
  "data-bs-toggle": "tooltip",
  "data-bs-placement": "right",
  "data-bs-trigger": "hover"
}
const _hoisted_2 = {
  key: 0,
  id: "view_as_admin"
}
const _hoisted_3 = {
  key: 1,
  id: "view_as_client"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.is_admin && _ctx.is_client)
    ? (_openBlock(), _createElementBlock("button", _hoisted_1, [
        (_ctx.is_admin)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "View as Client"))
          : _createCommentVNode("", true),
        (_ctx.is_client)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "View as Admin"))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}