
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import AuthModule from "@/store/modules/AuthModule";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import router from "@/router/router";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const { t } = useI18n();
    const signOut = () => {
      store
          .dispatch(Actions.SIGNOUT)
          .then(() => router.push({ name: "dashboard" }));
    };
    onMounted(() => {
      ToggleComponent.reinitialization();
    });
    const isAuthenticated = store.getters.isUserAuthenticated;
    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      isAuthenticated,
      signOut,
      t,
    };
  },
});
