
export default class UrlHelper {

    /**
     * Gets current request hostname
     */
    public static getRequestHostname()  {
        return '188.166.121.7';
        if(process.env.environment == 'dev') {
            return 'http://188.166.121.7';
        }
        return window.location.hostname;
    }
    /**
     * Gets current request url parameters
     */
    public static getUrlParam(key)  {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }
}

