import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "d-flex align-items-stretch ms-1 ms-lg-3" }
const _hoisted_3 = {
  class: "d-flex language-switcher align-items-center ms-1 ms-lg-3 me-3",
  "data-kt-menu-trigger": "hover",
  "data-kt-menu-placement": "bottom-start"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4",
  "data-kt-menu": "true",
  id: "countries"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "symbol symbol-20px me-4" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = {
  key: 0,
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_10 = {
  class: "cursor-pointer symbol symbol-30px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  src: "/media/avatars/blank.png",
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTSearch = _resolveComponent("KTSearch")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTSearch)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        class: "w-15px h-15px rounded-1 ms-2 me-2",
        src: _ctx.currentLangugeLocale?.flag,
        alt: "Language"
      }, null, 8, _hoisted_4),
      _createTextVNode(" " + _toDisplayString(_ctx.currentLangugeLocale.name), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "menu-item px-3"
        }, [
          _createElementVNode("a", {
            onClick: ($event: any) => (_ctx.setLang(index)),
            href: "#",
            class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage(index) }])
          }, [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("img", {
                class: "rounded-1",
                src: item.flag,
                alt: item.name
              }, null, 8, _hoisted_8)
            ]),
            _createTextVNode(" " + _toDisplayString(item.name), 1)
          ], 10, _hoisted_6)
        ]))
      }), 128))
    ]),
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (_ctx.user.avatar)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.site_url +'/'+ _ctx.user.avatar,
                  alt: ""
                }, null, 8, _hoisted_11))
              : (_openBlock(), _createElementBlock("img", _hoisted_12))
          ]),
          _createVNode(_component_KTUserMenu, { user: _ctx.user }, null, 8, ["user"])
        ]))
      : _createCommentVNode("", true)
  ]))
}