const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Home",
        route: "/",
        svgIcon: "media/icons/duotune/general/gen001.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "PAGES",
  },
];

export default DocMenuConfig;

