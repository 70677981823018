
import {computed, defineComponent, reactive} from "vue";
import KTSearch from "@/layout/header/partials/Search.vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "topbar",

  computed: {
    user() {
      return store.getters.getUserInfoAll;
    }
  },


  setup() {
    const site_url = process.env.VUE_APP_BACKEND_URL
    const i18n = useI18n();
    const store = useStore();

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
      location.reload();
    };
    store.dispatch(Actions.GET_USER);

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English",
      },
      ru: {
        flag: "/media/flags/russia.svg",
        name: "Russian",
      },
      am: {
        flag: "/media/flags/armenia.svg",
        name: "Armenian",
      },
    };

    const ln = localStorage.getItem("lang");
    i18n.locale.value = (ln && (ln in countries)) ? (ln as string) : "en";
    localStorage.setItem('lang', i18n.locale.value)
    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };
    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });
    const isAuthenticated = store.getters.isUserAuthenticated;

    return {
    setLang,
    currentLanguage,
    currentLangugeLocale,
    countries,
    isAuthenticated,
      site_url

  }
  },
  components: {
    KTSearch,
    KTUserMenu,

  },

});
